import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import Loadable from '@loadable/component'
import Navi from 'components/navi'
import Meta from 'components/meta'
// import Layout from 'components/layout'
import VisibilitySensor from 'react-visibility-sensor'
import Scrollspy from 'react-scrollspy'
import Scroll from '../../pages/Scroll'
import './style.scss'

class Reports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: get(this.props.data, 'contentfulReport.pages'),
      reports: get(this.props.data, 'contentfulReportCategories'),
      pageData: get(this.props.data, 'contentfulReport'),
      activeCategory: '',
      nextPage: '',
      previousPage: '',
    }
  }

  onCategoryClick = e => {
    if (e) {
      this.setState({ activeCategory: e })
    }
  }

  componentDidMount() {
    this.state.categories.forEach((element, index) => {
      if (element.slug == this.state.reports.slug) {
        this.setState({
          nextPage:
            index + 1 < this.state.categories.length
              ? this.state.categories[index + 1].slug
              : '',
          previousPage: index != 0 ? this.state.categories[index - 1].slug : '',
        })
      }
    })
    if (typeof window !== `undefined` && window) {
      window.addEventListener('scroll', e => {
        let navbar = document.getElementById('navbar')
        let reportHeader = document.getElementById('report-header')
        if (navbar) {
          navbar.classList.add('js-is-sticky')
        }
        if (reportHeader) {
          reportHeader.classList.add('js-is-sticky-header')
        }

        if (window.scrollY === 0) {
          if (navbar) {
            navbar.classList.remove('js-is-sticky')
          }
          if (reportHeader) {
            reportHeader.classList.remove('js-is-sticky-header')
          }
        }
      })
    }
    this.setState({
      activeCategory: this.state.reports.subCategories
        ? this.state.reports.subCategories[0].name
        : '',
    })
  }

  onVisibilityChange = (event, name) => {
    if (event && name && typeof window !== `undefined` && window.scrollY > 0) {
      this.setState({ activeCategory: name })
    }
  }

  render() {
    const {
      categories,
      activeCategory,
      reports,
      nextPage,
      previousPage,
      pageData,
    } = this.state
    const { location } = this.props
    const siteMetadata = {
      title: reports.name + ' | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/' + reports.slug,
    }
    return (
      <div>
        <Navi title={siteMetadata.title} />
        <Meta site={siteMetadata} />
        <div className="content-wrapper report-content-wrap" id="report-header">
          <div className="report-cover-bnr">
            <div className="report-cover-bg">
              <div className="container">
                <div className="wraper-report-cover">
                  <h2 className="mb-0 wh">{pageData.heading1 ? pageData.heading1 : ''}</h2>
                  <h5 className="wh">{pageData.heading2 ? pageData.heading2 : ''}</h5> </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-row align-items-center flex-1 justify-content-start menu-items report-header-wrapper">
            {categories.map((category, index) => {
              return category.isMainCategory == true ? (
                <div className="" key={index}>
                  <Link
                    className={
                      category.name == reports.name ? 'active' : 'inactive'
                    }
                    onClick={e =>
                      this.setState({
                        activeCategory: category.subCategories[0].name,
                      })
                    }
                    to={'/report/' + category.slug}
                  >
                    {category.name}
                  </Link>
                </div>
              ) : (
                  ''
                )
            })}
          </div>
          <div className="report-wrapper mt-5 container-fluid">
            <div className="d-flex flex-column flex-md-row">
              <div className="sidebar-wrapper">
                {/* <ul id="controlled-tab-example"> */}
                <ul id="">
                  {reports.subCategories
                    ? reports.subCategories.map((page, index2) => {
                      return (
                        <li
                          key={index2}
                          className={
                            page.name == activeCategory
                              ? 'active'
                              : 'inactive'
                          }
                          onClick={e => this.onCategoryClick(page.name)}
                        >
                          <Scrollspy
                            items={[page.name]}
                            className="slider-quote-btn scrollContentData pl-0"
                          >
                            <Scroll type="id" element={page.name}>
                              {page.name}
                            </Scroll>
                          </Scrollspy>
                        </li>
                      )
                    })
                    : ''}
                </ul>
              </div>
              <div className="main-data border-left">
                {/* {reports ? (
                  <div className="mb-4" id={reports.name}>
                    {reports.sections
                      ? reports.sections.map((section, i) => {
                          const Module = Loadable(() =>
                            import(`../reportSections/${section.name}`)
                          )
                          {
                            return (
                              <div key={i} className={section.name}>
                                <Module pageData={reports} />
                              </div>
                            )
                          }
                        })
                      : 'Not Found'}
                  </div>
                ) : (
                  'Not Found'
                )} */}
                {reports.subCategories
                  ? reports.subCategories.map((item, index) => {
                    return (
                      <div className="mb-4" key={index} id={item.name}>
                        <div className="category-header d-flex flex-row align-items-center justify-content-between">
                          <div className="">
                            <VisibilitySensor
                              onChange={e =>
                                this.onVisibilityChange(e, item.name)
                              }
                            >
                              <h2>{item.name}</h2>
                            </VisibilitySensor>
                          </div>
                          <div className="">
                            {reports.subCategories.length - 1 == index ? (
                              <div className="pagination text-center d-flex flex-row align-items-center justify-content-center">
                                <Link
                                  to={'/report/' + previousPage}
                                  className={
                                    previousPage ? 'enable' : 'disabled'
                                  }
                                >
                                  <i className="feather-chevron-left"></i>
                                </Link>
                                <Link
                                  to={'/report/' + nextPage}
                                  className={nextPage ? 'enable' : 'disabled'}
                                >
                                  <i className="feather-chevron-right"></i>
                                </Link>
                              </div>
                            ) : (
                                ''
                              )}
                          </div>
                        </div>

                        <div className="full-data-bind d-flex flex-row align-items-start justify-content-between">
                          {item.sections
                            ? item.sections.map((section, i) => {
                              const Module = Loadable(() =>
                                import(`../reportSections/${section.name}`)
                              )
                              {
                                return (
                                  <div key={i} className={section.name}>
                                    <Module pageData={item} />
                                  </div>
                                )
                              }
                            })
                            : 'Not Found'}
                        </div>
                        {/* {reports.subCategories.length - 1 == index ? (
                            <div className="pagination text-center d-flex flex-row align-items-center justify-content-center">
                              <Link
                                to={'/report/' + previousPage}
                                className={previousPage ? 'enable' : 'disabled'}
                              >
                                <i className="feather-chevron-left"></i>
                              </Link>
                              <Link
                                to={'/report/' + nextPage}
                                className={nextPage ? 'enable' : 'disabled'}
                              >
                                <i className="feather-chevron-right"></i>
                              </Link>
                            </div>
                          ) : (
                            ''
                          )} */}
                      </div>
                    )
                  })
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reports

export const pageQuery = graphql`
  query($slug: String) {
    contentfulReportCategories(slug: { eq: $slug }) {
      name
      isMainCategory
      slug
      bannerImage1 {
        file {
          url
        }
      }
      bannerImage2 {
        file {
          url
        }
      }
      bannerImage3 {
        file {
          url
        }
      }
      sections {
        name
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      subCategories {
        slug
        name
        isMainCategory
        parentCategoryName
        bannerImage1 {
          file {
            url
          }
          description
        }
        bannerImage2 {
          file {
            url
          }
          description
        }
        bannerImage3 {
          file {
            url
          }
          description
        }
        sections {
          name
        }
        description {
          childMarkdownRemark {
            html
          }
        }
        interpretation {
          childMarkdownRemark {
            html
          }
        }
        recommendations {
          childMarkdownRemark {
            html
          }
        }
        geneticDataNote {
          childMarkdownRemark {
            html
          }
        }
        geneticDataType {
          gene
          genoType
        }
        geneticDataResult {
          key
          value
        }
      }
    }
    contentfulReport {
      heading1
      heading2
      pages {
        name
        isMainCategory
        slug
        subCategories {
          isMainCategory
          name
          slug
        }
      }
    }
  }
`
